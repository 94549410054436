<template>
  <div class="page--error_with_code">
    <div class="content">
      <div class="message">{{ $te(`error.${$route.params.code}`) ? $t(`error.${$route.params.code}`) : $t(`error.generic`) }}</div>
      
    </div>
  </div>
</template>

<script>
export default {
  created() {
    
  }
}
</script>

<style lang="scss">
.page--error_with_code{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  .content{
    // max-width: 500px;
    text-align: center;
    .message{

    }
  }
}
</style>